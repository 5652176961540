import { INDEX_STEP } from '#core/constant'
import type { SectionLoader } from '#section/loader_types'
import { BOARD_SECTIONS_POSITION_FRAGMENT } from '#section/fragment'
import type { CreateSectionParams, SectionUpdatePayload } from '#section/types'
import { TaskLevel } from '#task/constant'

export const useReadSectionsFromCache = (params: { boardId: string }) => {
  const { sections, loadSections } = useBoardSectionsLoader()
  loadSections(params.boardId)
  return sections.value
}

export const useCreateSection = () => {
  const { getOptimisticSection } = useSectionDataConvert()

  const createSection = async (params: CreateSectionParams) => {
    const { name, boardId, position } = params

    const sections = useReadSectionsFromCache({
      boardId,
    })
    const newPosition =
      position ??
      (sections.length ? (sections.pop()?.position || 0) + 1 : INDEX_STEP)

    const payload: SectionUpdatePayload = {
      name,
      board: boardId,
      position: newPosition,
    }
    const { mutate, error, loading } = useAddUpdateSectionMutation({
      variables: {
        input: payload,
      },
      optimisticResponse: {
        addUpdateSection: {
          section: getOptimisticSection(null, payload) as SectionLoader,
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateSection?.section) {
          // Update the cache with the new item
          const { listCache } = useSectionSync()
          listCache.add(boardId, data.addUpdateSection.section)
        }
      },
    })

    if (loading.value) {
      return
    }

    const data = await mutate()
    if (error.value || !data?.data?.addUpdateSection) {
      return {
        error,
      }
    }

    return { data }
  }

  return {
    createSection,
  }
}

export const useSectionMove = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const { getSectionIdentifier } = useSectionDataConvert()

  const updateOptimisticPosition = (
    sectionId: string,
    boardId: string,
    position: number
  ) => {
    client.cache.modify({
      id: getSectionIdentifier(sectionId),
      fields: {
        boardId: () => boardId,
        position: () => position,
      },
      optimistic: true,
    })
  }

  const moveSectionToIndex = async (
    sectionId: string,
    targetIndex: number,
    boardId = currentBoard.value.id
  ) => {
    const fragmentOption = {
      id: getSectionIdentifier(sectionId),
      fragment: BOARD_SECTIONS_POSITION_FRAGMENT,
    }
    const sections = useReadSectionsFromCache({ boardId })
    const section = client.readFragment(fragmentOption)
    const sectionIndex = sections.findIndex(
      (section) => section.id === sectionId
    )
    if (!section || sectionIndex === targetIndex) {
      return
    }

    const position = calculateItemPosition(targetIndex, sections, section)
    updateOptimisticPosition(sectionId, boardId, position)
    const { mutate, error } = useUpdateSectionPositionMutation({
      variables: {
        sectionId,
        position,
      },
    })
    const data = await mutate()
    if (error.value || !data?.data?.updateSectionPosition.success) {
      return updateOptimisticPosition(
        sectionId,
        section.boardId,
        section.position
      )
    }
  }

  return {
    moveSectionToIndex,
  }
}

export const useUpdateSection = () => {
  const { client } = useApolloClient()
  const { getSectionIdentifier, getOptimisticSectionFields } =
    useSectionDataConvert()

  const updateSection = async (id: string, payload: SectionUpdatePayload) => {
    client.cache.modify({
      id: getSectionIdentifier(id),
      fields: getOptimisticSectionFields(payload),
      optimistic: true,
    })

    const { mutate } = useAddUpdateSectionMutation({
      variables: {
        input: {
          id,
          ...payload,
        },
      },
      update: (_, { data }) => {
        if (data?.addUpdateSection) {
          const { listCache } = useSectionSync()
          listCache.update(
            data.addUpdateSection.section.boardId,
            data?.addUpdateSection.section
          )
          if (payload.status) {
            const boardTasks = useReadTasksFromCache(
              data.addUpdateSection.section.boardId
            )
            const sectionTasks = boardTasks.filter(
              (task) =>
                task.sectionId === data.addUpdateSection.section.id &&
                task.level !== TaskLevel.SUBTASK
            )
            if (!sectionTasks.length) {
              return
            }

            const { getTaskIdentifier } = useTaskDataConvert()
            Promise.all(
              sectionTasks.map((task) => {
                client.cache.modify({
                  id: getTaskIdentifier(task.id),
                  fields: {
                    statusId: () => payload.status,
                  },
                })
              })
            )
          }
        }
      },
    })
    await mutate()
  }

  return {
    updateSection,
  }
}
