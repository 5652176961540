import { cloneDeep } from 'lodash-es'
import type { SectionLoader } from '#section/loader_types'

const $useBoardSectionsLoader = () => {
  const currentBoardId = ref<string | null>(null)

  const { result, load, loading, refetch } = useSectionsLazyQuery()

  const sections = computed(() => {
    return cloneDeep(result.value?.sections || [])
      .filter((section) => !section.closed)
      .sort(sortPos)
  }) as Ref<SectionLoader[]>

  const loadSections = async (boardId: string | null = null) => {
    if (!boardId) {
      const { currentBoard } = useWorkspaceSharedState()
      boardId = currentBoard.value.id
    }

    if (currentBoardId.value === boardId) {
      return
    }

    currentBoardId.value = boardId
    const loadResponse = load(null, { boardId })
    if (loadResponse === false) {
      return refetch({ boardId })
    }

    return loadResponse
  }

  return {
    sections,
    loadSections,
    sectionsLoading: loading,
  }
}

export const useBoardSectionsLoader = createSharedComposable($useBoardSectionsLoader)
